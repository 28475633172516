$(function () {

  // Validation - Override jquery validate plugin defaults
  $.validator.setDefaults({
    errorElement: "span",
    errorClass: "help-block",
    highlight: function (element, errorClass, validClass) {
      if ($(element).prop('type') === 'radio') {
        var name = $(element).prop('name');
        var radios = $('input[type=radio][name=' + name + ']');
        radios.closest('.form-group').addClass('has-error');
      } else {
        $(element).closest('.form-group').addClass('has-error');
      }
    },
    unhighlight: function (element, errorClass, validClass) {
      if ($(element).prop('type') === 'radio') {
        var name = $(element).prop('name');
        var radios = $('input[type=radio][name=' + name + ']');
        radios.closest('.form-group').removeClass('has-error');
      } else {
        $(element).closest('.form-group').removeClass('has-error');
      }
    },
    errorPlacement: function (error, element) {
      if (element.parent('.input-group').length || element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
        error.insertAfter(element.parent());
      } else {
        error.insertAfter(element);
      }
    }
  });

  // Validation - Validate forms
  $('.js-validate').each(function (index, element) {
    var form = $(element);
    form.validate({
      submitHandler: function (form) {
        var f = $(form);
        var modalId = f.data('modal');
        if (modalId) {
          $(modalId).modal('show');
        } else {
          f.find('[type=submit]').prop('disabled', 'disabled');
          f.submit();
        }
      }
    });
  });

  // Validation - Validate Ajax forms
  $('.js-validate-ajax').each(function (index, element) {
    var form = $(element);
    form.validate({
      submitHandler: function (form) {
        var f = $(form);
        f.find('[type=submit]').prop('disabled', 'disabled');
        $.post(
          f.prop('action'),
          f.serialize(),
          function (data) {
            if (data && data.Success) {
                window.location = "/Confirmation/Review";
            } else if (data && !data.Success) {
                var dataErrors = "";  // Collect errors that have a Key
              if (data.Messages) {
                  dataErrors += '<div class="alert alert-danger"><ul>';
                  for (var i = 0; i < data.Messages.length; i++) {
                    var msg = data.Messages[i];
                      dataErrors += "<li>" + msg.Message + "</li>";
                  }
                  dataErrors += "</ul></div>";
                  f.append(dataErrors);
              }
            }
          })
        ;
      }
    });
  });

  // Subnav bar - prevent multiple selected tabs
  $('.subnav a').on('click', function () {
    var clickedListItem = $(this).closest('li');
    var otherListItems = clickedListItem.siblings('li');
    otherListItems.removeClass('active');
  });

  // Funds - Calculate totals
  $('.js-single-fund-control').on('input', function () {
    var form = $(this).closest('form');
    var singleInputs = form.find('.js-single-fund-control');
    var totalOutput = form.find('.js-total-fund-control');
    var total = 0;

    singleInputs.each(function (index, element) {
      var input = $(element);
      var value = parseFloat(input.val());
      if (value) {
        total += value;
      }
    });

    totalOutput.val(total);
  });

  // Election - toggle table rows
  $('.js-toggle-table-rows').on('click', function (e) {
    e.preventDefault();
    var btn = $(this);
    var table = btn.closest('table');
    var rows = table.find('.toggle-row');
    if (rows.length > 0 && rows.first().is(':visible')) {
      btn.html('Show More Target Date Funds <i class="icon-expand"></i>');
      rows.addClass('hidden');
    } else {
      btn.html('Show Fewer Target Date Funds <i class="icon-collapse"></i>');
      rows.removeClass('hidden');
    }
  });

    $('.js-toggle-paragraph-block').on('click', function(e) {
      e.preventDefault();
      var btn = $(this);
      var paragraph_div = btn.closest('div');
      var paragraphs = paragraph_div.find('.toggle-p');
      if (paragraphs.length > 0 && paragraphs.first().is(':visible')) {
        btn.html('<b><u>Click here to read more</u></b>');
        paragraphs.addClass('hidden');
      } else {
        btn.html('<b><u>Click here to read less</u></b>');
        paragraphs.removeClass('hidden');
      }
    });

  // Tables - show scroll help text if wider than viewport
  updateTableScrollHelpText();
  $(window).on('resize', updateTableScrollHelpText);

    $('#language-selector').change(function() {
        var sel = this.value;
        console.log(sel);
        var submitData =
        { 
            language: sel
        };

        $.ajax({
            type: "POST",
            //url: '@Url.Action("UpdateLanguage", "Language")',
            url: $(this).data('url'),
            data: JSON.stringify(submitData),
            datatype: 'json',
            contentType: "application/json; charset=UTF-8"

        }).done(function(data) {
            location.reload();
        });
    });

    var selectedLanguage = $('#language-selector').data('language');
    if (selectedLanguage != "") {
        $('#language-selector').val(selectedLanguage);
    }
});

// Tables - show scroll help text if wider than viewport
function updateTableScrollHelpText() {
  $('.table-scroll').each(function () {
    var wrapper = $(this);
    var table = wrapper.children('table');
    var help = wrapper.siblings('.table-scroll-help');

    help.hide();
    if (table.outerWidth() > wrapper.outerWidth()) {
      help.show();
    }
  });
}