$(document).ready(function () {

    /**
     * MAKE SURE LINKS ARE CLICKED
     * Ensure a user has clicked all the links in the "acknowledge" label
     * 
     */
    $("#balpopup1terms").change(function (e) {
        if ($("#balpopup1terms").is(":checked")) {
            var numAnchors = $('label[for="balpopup1terms"]').find("a").length;
            var numClicked = $('label[for="balpopup1terms"]').find("a.jsLinkHasBeenClicked").length;

            if (numClicked < numAnchors) {
                var warningEnding = "all disclosures.";
                var singleOrPluralLink = "links";
                switch (numAnchors) {
                    case 1:
                        warningEnding = "the disclosure.";
                        singleOrPluralLink = "link";
                        break;
                    case 2:
                        warningEnding = "all two disclosures.";
                        break;
                    case 3:
                        warningEnding = "all three disclosures.";
                }
                $(this).removeAttr("checked");
                alert("You have opened " + numClicked + " out of " + numAnchors + " required disclosures. Please click on the " + singleOrPluralLink + " in the acknowledgement below to open and review " + warningEnding);
            }
        }
    });

    $('label[for="balpopup1terms"]').find("a").click(function (e) {
        $(this).addClass("jsLinkHasBeenClicked");
    });


    $("#balpopup2terms").change(function (e) {
        if ($("#balpopup2terms").is(":checked")) {
            var numAnchors = $('label[for="balpopup2terms"]').find("a").length;
            var numClicked = $('label[for="balpopup2terms"]').find("a.jsLinkHasBeenClicked").length;

            if (numClicked < numAnchors) {
                var warningEnding = "all disclosures.";
                var singleOrPluralLink = "links";
                switch (numAnchors) {
                    case 1:
                        warningEnding = "the disclosure.";
                        singleOrPluralLink = "link";
                        break;
                    case 2:
                        warningEnding = "all two disclosures.";
                        break;
                    case 3:
                        warningEnding = "all three disclosures.";
                }
                $(this).removeAttr("checked");
                alert("You have opened " + numClicked + " out of " + numAnchors + " required disclosures. Please click on the " + singleOrPluralLink + " in the acknowledgement below to open and review " + warningEnding);
            }
        }
    });

    $('label[for="balpopup2terms"]').find("a").click(function (e) {
        $(this).addClass("jsLinkHasBeenClicked");
    });

});